import React from "react";
import Col from "react-bootstrap/Col";
import "../../style/AdvantageUseType4.css";
import imgMachine from '../../res/img/machine.png';
import logoAlphaBg from '../../res/img/logo.alpha.png';
import imgLargeAdvantage3 from '../../res/img/img.large.advtg3.png';
import imgMachine2 from '../../res/img/machine2.png';
import imgClearPassBlue from '../../res/img/ClearPassBlue.png';

import Img1Adv4 from '../../res/img/advantages/adv3/img1.png';
import Img2Adv4 from '../../res/img/advantages/adv3/img2.png';
import Img3Adv4 from '../../res/img/advantages/adv3/img3.png';
import Img4Adv4 from '../../res/img/advantages/adv3/img4.png';

import { SRLWrapper } from "simple-react-lightbox";

const captionOne = <div myCustomCaptionOne="">
                        <h1>No other way to see it</h1>
                        <p>Today, Apollo MedCO sees contributing 10% of its pre-tax profits to various Altruistic 
                            programs accross the United States & the world as a priviledge and obligation</p>
                    </div>

const captionTwo = <div myCustomCaptionOne="">
                        <h1>No light? Then Choose!</h1>
                        <p>Today, Apollo MedCO sees contributing 10% of its pre-tax profits to various Altruistic 
                            programs accross the United States & the world as a priviledge and obligation</p>
                    </div>

const captionThree = <div myCustomCaptionOne="">
                        <h1>Profitable operators</h1>
                        <p>Today, Apollo MedCO sees contributing 10% of its pre-tax profits to various Altruistic 
                            programs accross the United States & the world as a priviledge and obligation</p>
                    </div>

const captionFour = <div myCustomCaptionOne="">
                        <h1>Anyone, except pets</h1>
                        <p>Today, Apollo MedCO sees contributing 10% of its pre-tax profits to various Altruistic 
                            programs accross the United States & the world as a priviledge and obligation</p>
                    </div>
 


const customCaptions = [
    { id: 0, caption: captionOne },
    { id: 1, caption: captionTwo },
    { id: 2, caption: captionTwo },
    { id: 3, caption: captionTwo }
  ]

class AdvantageUseType4 extends React.Component{
    getImageList = (number) => {
        if(number === 1){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img className="imageMainBackground" src={Img1Adv4}/>
                    <img  style={{display:"none"}}  className="imageMainBackground" src={Img2Adv4}/>
                    <img  style={{display:"none"}}  className="imageMainBackground" src={Img3Adv4}/>
                    <img  style={{display:"none"}}  className="imageMainBackground" src={Img4Adv4}/>
                </SRLWrapper>
            );
        }else if(number === 2){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img  style={{display:"none"}} className="imageMainBackground" src={Img1Adv4}/>
                    <img  className="imageMainBackground" src={Img2Adv4}/>
                    <img  style={{display:"none"}}  className="imageMainBackground" src={Img3Adv4}/>
                    <img  style={{display:"none"}}  className="imageMainBackground" src={Img4Adv4}/>
                </SRLWrapper>
            );
        }else if(number === 3){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img style={{display:"none"}} className="imageMainBackground" src={Img1Adv4}/>
                    <img style={{display:"none"}}  className="imageMainBackground" src={Img2Adv4}/>
                    <img className="imageMainBackground" src={Img3Adv4}/>
                    <img style={{display:"none"}}  className="imageMainBackground" src={Img4Adv4}/>
                </SRLWrapper>
            );
        }else if(number === 4){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img style={{display:"none"}} className="imageMainBackground" src={Img1Adv4}/>
                    <img style={{display:"none"}} className="imageMainBackground" src={Img2Adv4}/>
                    <img style={{display:"none"}} className="imageMainBackground" src={Img3Adv4}/>
                    <img className="imageMainBackground" src={Img4Adv4}/>
                </SRLWrapper>
            );
        }
    };

    render(){
        return (
            <Col  md={6} lg={3} className="advantageUsing4">
               <div className="imageBackground">
                   
               </div>
               
               {this.getImageList(this.props.number)}
              


               <div className="divBorderMainBckgAvtg4"></div>
               <div className="divBorderAvtg4"></div>
               <div className="divBorder2Avtg4"></div>
               <img className="smallImage" src={this.props.smallimg}/>
                <div className="zoneForeGround">
                    <div>
                        <div class="zoneNoOtherWayToSeeIt">{this.props.title}</div>
                        <div className="todayApolloMedco">
                            Today, Apollo MedCO sees contributing 10% of its pre-tax profits to various Altruistic 
                            programs accross the United States & the world as a priviledge and obligation
                        </div>
                        <div className="zoneSmallerText">
                            <div className="imageUsing4Machine">
                                <img src={imgMachine2} />
                            </div>
                            <div className="textUsing4SmallerText">
                                Today, Apollo MedCO sees contributing 10% of its pre-tax profits to various Altruistic 
                                programs accross the United States & the world as a priviledge and obligation
                                <div className="imgClearPassBlueUsing4">
                                    <img src={imgClearPassBlue} />
                                </div>
                            </div>
                    
                        </div>
                    </div>
                </div>
            </Col>
        );
    }
}

export default AdvantageUseType4;