import React from 'react';

import BgPeople from '../res/img/training/bg.people.png';
import BgPeopleMeeting from '../res/img/training/bg.people.meeting.png';
import ClearPassBlue from '../res/img/training/clearpassblue.png';
import Machine from '../res/img/training/machine.png';
import PersonYes from '../res/img/training/person.yes.png';
import IconApolloBlack from '../res/img/training/icon.apollo.black.png';
import Vortex from '../res/img/training/vortex.png';

import Button from 'react-bootstrap/Button';


import '../style/TrainingSupport.css';


function TrainingSupport(){
   return (
        <React.Fragment>
            <div id="reactPageTrainingSupport_">
            <div className="bigBackgroundsInApp" id="topDivTrainingSupport" style={styleTopDiv}></div>
            <div id="Two-of-the-most-valued-elements">
                <div>
                    Two of the most valued elements in our <b>Vortex of Reliability</b> are our robust support structure 
                    and our never-ending training.
                </div>
            </div>
            <div id="zoneDisplayCleanPassBlue">
                <img id="cleanPassBlue" src={ClearPassBlue}/><br/>
                <img id="machineCleanPass" src={Machine}/>
            </div>
            <div id="zonePeopleMeetingTraining"></div>
            <div id="More-coming-soon">
                <div>
                    <strong>More coming soon</strong>
                    <p>Lorem ipsum dolor sit amet, consectur adipsicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum supendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilis.</p>
                    <p>Lorem ipsum dolor sit amet, consectur adipsicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum supendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilis.</p>
                </div>
            </div>
            <div id="ChooseBusinessOrClinic" style={styleDivPersonYes}>
                <div>
                    <p>
                        <div><Button className="buttonApollo">I am a<br/> business</Button></div>
                        <div><Button className="buttonApollo">I am a<br/> doctor/clinic</Button></div>
                    </p>
                    <img id="IconApolloBlack" src={IconApolloBlack}/>
                </div>
            </div>
            <div id="zoneDisplayVortexImage" style={styleShowVortexTraining}>
                <div>
                    <img src={Vortex}/>
                </div>
            </div>
            </div>
        </React.Fragment>
    );
}

const styleTopDiv = {
    width:'100%',
    background:'url('+BgPeople+') no-repeat fixed right',
    backgroundSize:'cover',
    color: '#ccc',
    height: '600px',
    position: 'absolute',
    top:'0px',
    left:'0px',
    zIndex:'-2',
    color:'black'
};


const styleDivPersonYes = {
    width:'100%',
    background:'url('+PersonYes+') no-repeat bottom',
    backgroundSize:'cover',
    color: '#ccc',
    height: '600px',
    zIndex:'-2',
    color:'black'
};

const styleShowVortexTraining = {
    width:'100%',
    background:'url('+IconApolloBlack+') no-repeat center ',
    backgroundSize:'cover',
    color: '#ccc',
    height: '400px',
    zIndex:'-2',
    color:'black',
    overflow:'hidden'
};

export default TrainingSupport;
