import React from 'react';

import "../style/Footer.css";
import logoAlphaBg from '../res/img/footer/apollo-black.png';
import facebookIcon from '../res/img/footer/facebook-icon.png';
import linkedinIcon from '../res/img/footer/linkedin-icon.png';
import twitterIcon from '../res/img/footer/twitter-icon.png';


export default () => {
    return(
        <div id="zoneFooter">
            <div className="menuFooter"><img src={logoAlphaBg}/></div>
            <div className="menuFooter" id="menuFooterHomeUs">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/us">Us</a></li>
                </ul>
            </div>
            <div className="menuFooter">
                <ul>
                    <li><a href="/vortex">Vortex of reliability</a></li>
                    <li><a href="/products">Products</a></li>
                    <li><a href="/trainingandsupport">Training & Support</a></li>
                </ul>
            </div>
            <div className="menuSocialMedia">
                <ul>
                    <li><a href="#"><img src={facebookIcon}/></a></li>
                    <li><a href="#"><img src={twitterIcon}/></a></li>
                    <li><a href="#"><img src={linkedinIcon}/></a></li>
                </ul>
            </div>
        </div>
    )
};