import React from "react";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import styles from 'styled-components';
import manWithBread from '../res/img/man.bread.pic.png';
import cleanPassImg from '../res/img/clean.pass.covid.png';

const StylesJ = styles.div`
                /*.jumbotron{
                    background:url(${manWithBread}) no-repeat fixed bottom;
                    background-size:cover;
                    color: #ccc;
                    height: 500px;
                    position: relative;
                    z-index:-2;
                    color:black;
                }*/
                .jumbotron{
                    background:transparent;
                }

                .overlay{
                   background:transparent;
                }

                #imageCleanPassJumbotron{
                    position:absolute;
                    width:500px;
                    left:calc(50% - 250px);
                    top:7cm;
                }

                @media only screen and (max-width: 650px) {
                    #imageCleanPassJumbotron{
                        width:220px;
                        left:calc(50% - 110px);
                    }
                }
`;

class JumbotronA extends React.Component{
  render(){
    return(
        <StylesJ>
            <div id="fluidJumbutronZone" fluid className="jumbotron" style={{height:"600px"}}>
                <div className="overlay"></div>
                <img id="imageCleanPassJumbotron" src={cleanPassImg}/>
            </div>
        </StylesJ>
    );
  }
}

export default JumbotronA;
