import React from 'react';

import BryanPicture from '../res/img/us/bryanPicture.png';
import CharliePicture from '../res/img/us/CharliePicture.png';
import KenPicture from '../res/img/us/kenPicture.png';
import KidsPicture from '../res/img/us/kids.png';
import SmilingMan from '../res/img/us/smilingMan.png';
import ThreeGenerationWomen from '../res/img/us/threeGenerationWomen.png';
import ImageApolloOnlyBlackIcon from '../res/img/vortex/apollo.only.black.png';
import ImageApolloOnlyWhiteIcon from '../res/img/us/iconWhiteApollo.png';
import ImageDottedCurve from '../res/img/us/dottedCurve.png';
import Vortex from '../res/img/training/vortex.png';

import "../style/Us.css";

function Us(){
   return (
        <React.Fragment>
            <div id="pageReactUs_">
            <div id="premierDivUs" className="bigBackgroundsInApp" style={styleTopDiv}>
                <div id="zoneMissionVortextOfReliability">
                    <div>
                        <div><strong>Mission</strong></div>
                        <p>That due to Our <b>Vortex of Reliability</b> our product line will be the last thing you'll ever need to worry about.</p>   
                    </div>
                </div>
            </div>

            <div id="zonePrincipalSpeaking">
                <div><img id="ImageApolloOnlyBlackIconSpeaking" src={ImageApolloOnlyBlackIcon}/></div>
                <div className="principalSpeakingSection">
                    <p>"I keep thinking the best thing we can do right now is to bring families together, keep businesses and government working safely, and doing it around the world. That is  
                    eactly what we are doing at Apollo"</p>
                    <div className="personSpeaking">
                        <div className="personSpeakingName">Bryan Taylor</div>
                        <div className="personSpeakingTitle">Principal at Apollo Medco</div>
                    </div>
                </div>

                <div className="principalSpeakingSection">
                    
                    <p>"We look at problems as opportunities to innovate and continuously offer disruptive solutions which change the trajectory of medical care"<br/><br/><br/></p>
                    <div className="personSpeaking">
                        <div className="personSpeakingName">Ken Dunwody</div>
                        <div className="personSpeakingTitle">Principal at Apollo Medco</div>
                    </div>
                </div>
            </div>

            <div style={styleThreeWomenDiv} id="zoneThreeGenerationWomen">

            </div>

            <div className="zonePersonSpeaking" id="zoneKenDunWody">
                <img src={ImageApolloOnlyBlackIcon} id="iconBlackKenDunwody"/>

                

                <div>
                    <div className="profilePicture" style={{float:"right"}}>
                        <img src={KenPicture}/>
                    </div>
                    <div className="zoneBiography" style={{float:"left"}}>
                        <div className="bioPersonName">Ken Dunwody</div>
                        <div className="bioContent">
                            <p>Georgia Institute of Technology, Bachelor of Arts Electrical Engineering and Computer Science.</p>
                            <p>35 years of experience in International Satellite Telecommunications</p>
                            <p>5 years in the International Wireless Industry</p>
                            <p>Co-Founder and CEO of South American Bytes Peru, S.A.C</p>
                            <p>Founder, President & CEO, Phoenix Satcom & Wireless, LLC</p>
                            <p>Founder, President & CEO, Apollo MedCo, LLC</p>
                            <p>Avid Tennis player, Triathlete and Fitness junky</p>
                            <p>Proud Husband & Father</p>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="zonePersonSpeaking" id="zoneBryan">
                <img src={ImageApolloOnlyWhiteIcon} id="iconWhiteBryan"/>
                <div>
                    <div className="profilePicture profilePictureLeft">
                                <img src={BryanPicture}/>
                    </div>
                    <div className="zoneBiography zoneBiographyRight">
                        <div className="bioPersonName">Bryan Taylor</div>
                        <div className="bioContent">
                            <p>Founder & CEO of Anderson-Taylor, building sophisticated online software for companies like CNBC, Google, Miller Industries, Apartment Finder, NCI, MicroCorp, Sprint, UCN, Delta, MITSUBISHI, avaya AND nec 
                                which provide integration between businesses and their agent / affiliate channels and their customers.
                            </p>
                            <p>Founding Partner of Verisys Cloud Communications</p>
                            <p>Creator of Salesninja; the iPad sales system for manufactures and their distributors.</p>
                            <p>Proud graduate of S.C.A.D.(Savannah College of Art and Design</p>
                            <p>Former President of the International Customer Services Association</p>
                            <p>Former Partner for CDI, a successful Advertising and Marketing firm which he helped grow for 10 years before starting Anderson-Taylor</p>
                            <p>Past president of the ICSA, http:///www.icsa.cm/Georgia</p>
                            <p>Active involvement with ICSA, SOCAP CXPA and Wireless Technologies group Council & Operating Committee</p>
                            <p>Boy Scouts of America Leader.</p>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="zonePersonSpeaking" id="zoneCharlesJordan">
            <img src={ImageDottedCurve} id="iconDottedCharlesJorda"/>
                <div>
                <div className="profilePicture">
                        <img src={CharliePicture}/>
                    </div>
                    <div className="zoneBiography">
                        <div className="bioPersonName">Charles Jordan</div>
                        <div className="bioContent">
                            <ul>
                                <li>Founder The Charles Jordan Group</li>
                                <li>Betta Gamma Sigma Member</li>
                                <li>Frustrated Sommelier, Ironman Louisville & Ironman Chattonooga</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div style={styleKids} id="zoneKids">
                
            </div>

            <div id="zoneDisplayVortexImage" style={styleShowVortexTraining}>
                <div>
                    <img src={Vortex}/>
                </div>
            </div>
            </div>
        </React.Fragment>
    );
}

const styleTopDiv = {
    width:'100%',
    background:'url('+SmilingMan+') no-repeat fixed top',
    backgroundPositionY:'-3cm',
    backgroundSize:'cover',
    color: '#ccc',
    height: '700px',
    position: 'absolute',
    top:'0px',
    left:'0px',
    zIndex:'1200',
    color:'black'
};

const styleThreeWomenDiv = {
    width:'100%',
    background:'url('+ThreeGenerationWomen+') no-repeat ',
    backgroundPositionY:'-3cm',
    backgroundSize:'cover',
    color: '#ccc',
    height: '700px',
    zIndex:'-2',
    color:'black'
};

const styleKids = {
    width:'100%',
    background:'url('+KidsPicture+') no-repeat ',
    backgroundSize:'cover',
    color: '#ccc',
    height: '700px',
    zIndex:'-2',
    color:'black'
};

const styleShowVortexTraining = {
    width:'100%',
    background:'url('+ImageApolloOnlyBlackIcon+') no-repeat center ',
    backgroundSize:'cover',
    color: '#ccc',
    height: '400px',
    zIndex:'-2',
    color:'black',
    overflow:'hidden'
};

export default Us;
