import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import JumbotronA from '../Component/JumbotronA';
import { Redirect } from 'react-router-dom';
import manWithBread from '../res/img/man.bread.pic.png';
import '../style/Product.css';
import imgMachine from '../res/img/machine.png';
import logoAlphaBg from '../res/img/logo.alpha.png';
import AdvantageUse from '../Component/AdvantageUse/AdvantageUse';
import AdvantageUseType2 from '../Component/AdvantageUse/AdvantageUseType2';
import AdvantageUseType3 from '../Component/AdvantageUse/AdvantageUseType3';
import AdvantageUseType4 from '../Component/AdvantageUse/AdvantageUseType4';
import SmilingOldMan from '../res/img/smilingOldMan.png';

import Img1Adv2 from '../res/img/advantages/adv1/img1.png';
import Img2Adv2 from '../res/img/advantages/adv1/img2.png';
import Img3Adv2 from '../res/img/advantages/adv1/img3.png';
import Img4Adv2 from '../res/img/advantages/adv1/img4.png';

import Img1Adv3 from '../res/img/advantages/adv2/img1.png';
import Img2Adv3 from '../res/img/advantages/adv2/img2.png';
import Img3Adv3 from '../res/img/advantages/adv2/img3.png';
import Img4Adv3 from '../res/img/advantages/adv2/img4.png';

import Img1Adv4 from '../res/img/advantages/adv3/img1.png';
import Img2Adv4 from '../res/img/advantages/adv3/img2.png';
import Img3Adv4 from '../res/img/advantages/adv3/img3.png';
import Img4Adv4 from '../res/img/advantages/adv3/img4.png';

import SmallImg1Adv4 from '../res/img/advantages/adv3/img.small.1.png';
import SmallImg2Adv4 from '../res/img/advantages/adv3/img.small.2.png';
import SmallImg3Adv4 from '../res/img/advantages/adv3/img.small.3.png';
import SmallImg4Adv4 from '../res/img/advantages/adv3/img.small.4.png';

import LightboxExample from '../Component/LightBoxPopup';

import SimpleReactLightbox,{useLightbox} from "simple-react-lightbox";
import BoxPopup from '../Component/BoxPopup';

function Products(){
    return(
        <React.Fragment >
            {/*<LightboxExample/>*/}
            <div id="zonePageProductReact_">
            
            <div className="bigBackgroundsInApp" id="topBigBackgroundsInAppProduct" style={styleTopDiv}></div>
            <JumbotronA/>
            <div style={{color:"#4d4d4d"}} id="zoneTheCovidCleanPass">
                <div>
                    The <b>Covid Clean Pass</b> is a result of the finest engineering combined with the besst designed, most valuable advantages.
                    Organizations accross the globe are seeing the incomparable benefits the <b>Covid Clean Pass</b> is offering; <span style={{fontStyle:"italic"}}>benefits enabling them to open their businesses sonner and with commplete peace of mind</span>.
                </div>
            </div>
            <Row style={{color:"#4d4d4d"}} id="zoneWhileHassleFree">
                <Col md={4} id="zoneImageCleanPass">
                    <img style={{width:"320px"}} src={imgMachine}/>
                </Col>
                <Col md={5} id="labelImageCleanPass">
                    While hassle-free, simple to operate, and compatible with worldwide power sources are <b>just a few</b> of the benefits offered, 
                    the principals at Apollo MedCo still believe that it is <b>Reliability, an element rarely experienced today, that represents the 
                    critical foundation for the <b>Covid Clean Pass</b>. As result, our Clean Pass is enabling businesses reopen and start growing with absolute peace of mind.
                    </b>
                </Col>
                <Col md={3} id="zoneLogoCovidCleanPass">
                    <img src={logoAlphaBg}/>
                </Col>
            </Row>

            <Row>
                <Col style={{textAlign:"center",paddingTop:"1.5cm"}}>
                    <div id="zoneSimplyClickOnAnyImage" style={{width:"520px",display:"inline-block"}}>
                        Simply click on any image to view why the <b>COVID Clean Pass</b> is your most needed partner during these incredibly challenging times
                    </div>
                    <div>

                    </div>
                </Col>
            </Row>
            <SimpleReactLightbox>
            <Row style={{padding:"20px"}}>
                <AdvantageUse classes="advantageUsing advtgDesign1" title="Be back" text1="You can be back to work, at possibly full capacity, in a hassle-free way & with undeniable peace of mind." text2="Uncover today the unprecedented, 15 minute, APP linked, HIPAA Compliant, Cloud Secure, & Data Backed COVID Testing Opportunity."/>
                <AdvantageUse classes="advantageUsing advtgDesign2" title="Imagine perfect, being anywhere" text1="You can be back to work, at possibly full capacity, in a hassle-free way & with undeniable peace of mind." text2="Uncover today the unprecedented, 15 minute, APP linked, HIPAA Compliant, Cloud Secure, & Data Backed COVID Testing Opportunity."/>
                <AdvantageUse classes="advantageUsing advtgDesign3" title="First there" text1="You can be back to work, at possibly full capacity, in a hassle-free way & with undeniable peace of mind." text2="Uncover today the unprecedented, 15 minute, APP linked, HIPAA Compliant, Cloud Secure, & Data Backed COVID Testing Opportunity."/>
                <AdvantageUse classes="advantageUsing advtgDesign4" title="Worry about something else" text1="You can be back to work, at possibly full capacity, in a hassle-free way & with undeniable peace of mind." text2="Uncover today the unprecedented, 15 minute, APP linked, HIPAA Compliant, Cloud Secure, & Data Backed COVID Testing Opportunity."/>
            </Row>
            </SimpleReactLightbox>
            <SimpleReactLightbox>
            <Row style={{padding:"20px"}}>
                <AdvantageUseType2 number={1} img={Img1Adv2} img2={Img2Adv2} img3={Img3Adv2} img4={Img4Adv2} title="Don't just kiss under the mistletoe. Test as well"/>
                <AdvantageUseType2 number={2} img={Img2Adv2} title="A business doors, opening app"/>
                <AdvantageUseType2 number={3} img={Img3Adv2} title="Bye bye & comply"/>
                <AdvantageUseType2 number={4} img={Img4Adv2} title="Keep them! Since all are true Mvp's"/>
            </Row>
            </SimpleReactLightbox>
            <SimpleReactLightbox>
            <Row style={{padding:"20px"}}>
                <AdvantageUseType3 number={1} img={Img1Adv3} title="1 single minute"/>
                <AdvantageUseType3 number={2} img={Img2Adv3} title="Welcome a blackout!"/>
                <AdvantageUseType3 number={3} img={Img3Adv3} title="Electricity? That's so 2020"/>
                <AdvantageUseType3 number={4} img={Img4Adv3} title="No joke. Only 1/2 page instructional manual"/>
            </Row>
            </SimpleReactLightbox>

            <SimpleReactLightbox>
            <Row style={{paddingLeft:"20px",paddingRight:"20px",marginBottom:"1cm"}}>
                <AdvantageUseType4 number={1} img={Img1Adv4} smallimg={SmallImg1Adv4} title="No other way to see it"/>
                <AdvantageUseType4 number={2} img={Img2Adv4} smallimg={SmallImg2Adv4} title="No light? Then Choose!"/>
                <AdvantageUseType4 number={3} img={Img3Adv4} smallimg={SmallImg3Adv4} title="Profitable operators"/>
                <AdvantageUseType4 number={4} img={Img4Adv4} smallimg={SmallImg4Adv4} title="Anyone, except pets"/>
            </Row>
            </SimpleReactLightbox>
            
            <Row id="RowAndhowdoyoubegintoe" style={{background:"#856379",color:"white"}}>
                <Col style={{textAlign:"center",paddingTop:"1.5cm",paddingBottom:"1.5cm"}}>
                    <div id="Andhowdoyoubegintoe" style={{width:"520px",display:"inline-block"}}>
                        And how do you begin to experience aall these benefits? Simply observer our:<div><b>Road To Implementation</b></div>
                        <small>Coming soon ... Road Map DESIGN To Implemnetation</small>
                    </div>
                </Col>
            </Row>

            <Row style={{marginBottom:"1cm"}}>
                <Col style={{textAlign:"center",paddingTop:"1.5cm"}}>
                    <div id="Loremipsumdolor-sit-amet" style={{width:"520px",display:"inline-block"}}>
                        <p>
                        Lorem ipsum dolor sit amet, consectur adipsicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum supendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilis.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectur adipsicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum supendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilis.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectur adipsicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum supendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilis.
                        </p>
                    </div>
                </Col>
            </Row>

            <div style={{maxHeight:"700px",overflow:"hidden"}}>
                <img id="imageSmilingOldMan" style={{height:"1200px",position:"relative",top:"-4cm"}} src={SmilingOldMan}/>
            </div>
            </div>
        </React.Fragment>
        
    )
}

const styleTopDiv = {
    width:'100%',
    background:'url('+manWithBread+') no-repeat fixed top',
    backgroundSize:'cover',
    color: '#ccc',
    height: '700px',
    position: 'absolute',
    top:'0px',
    left:'0px',
    zIndex:'-2',
    color:'black'
};

export default Products;
