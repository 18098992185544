import React from 'react';
import {Parallax} from 'react-scroll-parallax';
import {Row,Col} from 'react-bootstrap';
import BgBee from '../res/img/vortex/beebg.png';
import "../style/Vortex.css";
import ImageGreeLeaves from '../res/img/vortex/green_leaves.png';
import ImageApolloOnlyBlackIcon from '../res/img/vortex/apollo.only.black.png';
import ImageVortex from '../res/img/vortex/vortex.png';
import BgMountain from '../res/img/vortex/bg.mountain.png';


function Vortex(){
   return (
        <React.Fragment>
            <div id="pageVortexReact_">
            <div id="premierDivVortex"  className="bigBackgroundsInApp" style={styleTopDiv}></div>
            <div className="divAfterBigDivStarting" id="zonePioneeringAsOurEntire">
                <div>
                    "As pioneering as our entire line is, we actually belive it's our <b>reliability</b>, in 
                    more ways than one, that towers over all the advantages we offer. In today's world, <b>true reliability</b> is 
                    rarely experienced but at Apollo MedCo, it is cornerstone to who we are."
                    <p>
                        <small>After, what good is pioneering technology if you can't have it when you need it, 
                            can't understand how to use it, or may be continuously breaking down?""
                        </small>
                    </p>
                </div>
            </div>
            <Row id="zoneEveryTimeYouSeeAsolution">
                <Col id="zoneImageGreeLeaves" md={5}><img src={ImageGreeLeaves}/></Col>
                <Col id="zoneImageGreeLeavesText" md={5}>
                    <p>Every time you see a solution Apollo MedCo, <b>be rest assured</b> that it is characterized by the 5 elements found in our <b>Vortex of Reliability</b>.</p>
                    <p>
                        These elements are working together to ensure you have complete peace of mind as you reopen your business, gather with family embers again, and at long last, begin to move forward.
                    </p>
                </Col>
            </Row>
            <div id="zoneImageApolloAndVortex">
                <img id="ImageApolloOnlyBlackIcon" src={ImageApolloOnlyBlackIcon}/>
                <div id="ImageVortex">
                    <img src={ImageVortex}/>
                </div>
            </div>
            <div  className="bigBackgroundsInApp" id="zoneBackgroundMountain">
                <div id="zoneGrayInZoneBackgroundMountain">
                    <p>
                        It's What we promised<br/>
                        It's What defies our line<br/>
                        It's waht you should expect<br/>
                        &
                        It's why you'll continue To purchase from use<br/>
                        For years to come<br/>
                    </p>
                </div>
            </div>
            <div id="BecauseReliabilityIsTruly">
                Because Reliability is Truly Innovative&trade;
            </div>
            <div id="zoneVision">
                <div>
                    <p><strong>Vision:</strong></p>
                    <p>That you be astonished experiencing first-hand the many profitable benefits of working with a compnay that has made <b>true & complete reliability</b> its principal focus.
                    </p>
                </div>
            </div>
            </div>
        </React.Fragment>
    );
}

const styleTopDiv = {
    width:'100%',
    background:'url('+BgBee+') no-repeat fixed top',
    backgroundSize:'cover',
    color: '#ccc',
    height: '600px',
    position: 'absolute',
    top:'0px',
    left:'0px',
    zIndex:'-2',
    color:'black'
};

export default Vortex;
