import React from 'react';
import Container from 'react-bootstrap/Container';
import styles from 'styled-components';

const StylesContainer = styles.div`
                        .containerBootstrap{
                            margin: 0 !important;
                            padding : 0 !important;
                        }
`;
class Layout extends React.Component{
    render(){
        return (
            <StylesContainer>
                <Container className="containerBootstrap" fluid>
                    {this.props.children}
                </Container>
            </StylesContainer>
        );
    }
}

export default Layout;