import React from 'react';
import styles from 'styled-components';
import logoApollo from '../res/img/logo.alpha.png';
import bgImgBigA from '../res/img/a.icon.transparent.png';
import smileMan from '../res/img/smile.man.png';
import Parallax from 'react-parallax';
import Button from '@material-ui/core/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import grey from '@material-ui/core/colors/grey';
import FormLandPage from '../Component/FormLandPage';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller, animateScroll } from 'react-scroll'




const StyledBody = styles.body`
                        body{
                           
                        }

                        .outerDiv{
                            background:linear-gradient(180deg,#8b697f 20%,#705466 50%);
                        }

                        .innerDiv{
                            background:url(${smileMan}) repeat fixed top;
                            background-size:cover;
                            padding-bottom:1cm;
                        }
                        
                        .mainDiv{
                            width:100%;
                            height:100%;
                            padding-top:5cm;
                            margin-top:-2cm;
                            z-index:100;
                            color:white;
                            text-align:center;
                            z-index:-20;
                            opacity:1;
                        }

                        

                        .pardonDiv{
                            text-transform:uppercase;
                            font-size:60px;
                            font-weight:600;
                            letter-spacing:10px;
                        }

                        .theInconvenienceDiv{
                            font-size:30px;
                            text-transform:uppercase;
                            transform:rotateZ(90deg);
                            position:relative;
                            left:160px;
                            font-weight:100;
                        }

                        .enhancingDiv{
                            display:inline-block;
                            position:relative;
                            top:-30px;
                            left:-120px;
                            font-style:italic;
                            font-size:20px;
                            font-weight:100;
                        }

                        .zoneInAFewWeeks{
                            display:inline-block;
                            width:250px;
                            text-align:left;
                            font-weight:100;
                            position:relative;
                            top:-20px;
                        }

                        .logoApolloMedco{
                            width:300px;
                            position:relative;
                            left:-10px;
                        }

                        .outerDivImg{
                            background:url(${bgImgBigA}) no-repeat fixed bottom;
                            background-size:cover;
                            background-size: 700px;
                            background-position: top;
                            z-index:10;
                        }

                        .btnRequestForTest{
                            background:#da783b;
                            border:none;
                            box-shadow:0px 0px 5px #da783b;
                            color:white;
                            text-transform:uppercase;
                        }

                        @media only screen and (min-width: 651px) {
                            .outerDivImg{
                                background-size: 700px;
                                background-position-x:71%;
                                background-position-y:-0.1cm;
                                overflow:hidden;
                            }
                        }

                        @media only screen and (max-width: 650px) {
                            .outerDivImg{
                                background:url(${bgImgBigA}) no-repeat fixed bottom;
                                background-size: 400px;
                                background-position-x:-90%;
                                background-position-y:40%;
                                overflow:hidden;
                                background:none;
                            }

                            .outerDiv{
                                background:linear-gradient(180deg,#8b697f 20%,#705466 50%);
                            }
                            
                            .mainDiv{
                                width:100%;
                                height:100%;
                                padding-top:5cm;
                                margin-top:-2cm;
                                z-index:100;
                                color:white;
                                text-align:center;
                                z-index:-20;
                                opacity:1;
                            }
    
                            .pardonDiv{
                                text-transform:uppercase;
                                font-size:40px;
                                font-weight:600;
                                letter-spacing:10px;
                            }
    
                            .theInconvenienceDiv{
                                font-size:30px;
                                text-transform:uppercase;
                                transform:rotateZ(0deg);
                                font-weight:100;
                                left:0px;
                            }
    
                            .enhancingDiv{
                                font-style:italic;
                                font-size:20px;
                                font-weight:100;
                                top:0px;
                                left:0px;
                                display:block;
                                font-weight:bold;
                            }
    
                            .zoneInAFewWeeks{
                                width:250px;
                                text-align:left;
                                font-weight:100;
                                position:relative;
                                top:-20px;
                            }
    
                            .logoApolloMedco{
                                width:200px;
                                position:relative;
                                left:-10px;
                            }
    
                            .btnRequestForTest{
                                background:#da783b;
                                border:none;
                                box-shadow:0px 0px 5px #da783b;
                                color:white;
                                text-transform:uppercase;
                            }
                        }
                        
                        

                       


`;


function handleOpenPopup(){

}

const handleGoToForm = () => {
    //alert("Yes");
    //animateScroll.scrollTo({toY:1000});
    scroll.scrollTo(window.innerHeight + 100);
}

function LandPage(){
    const [show, setShow] = React.useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <StyledBody>
            <div style={{minHeight: window.innerHeight + 'px',position:'absolute',top:'0px',width:'100%'}} className="outerDiv">
                <div className="outerDivImg">
                    <div class="innerDiv">
                        <div className="mainDiv">
                            <div className="pardonDiv">Pardon</div>
                                <div className="theInconvenienceDiv">The inconvenience</div>
                                <div className="enhancingDiv">We are enhancing our website</div><br/>
                                <p class="zoneInAFewWeeks">
                                    <p>In a few weeks, we will be debuting our new product line.</p>
                                    <p>Including one where as a business or clinic, you'll be able to have an unprecedented, 15 minute, APP linked, HIPAA Compliant, Cloud Secure, & Data Backed COVID Testing Opportunity.</p>
                                    
                                </p>
                                <p>
                                    <div>
                                        <Button onClick={handleGoToForm} className="btnRequestForTest" variant="warning">
                                            <KeyboardArrowDown></KeyboardArrowDown>
                                            Request for COVID-19 Testing
                                            <KeyboardArrowDown></KeyboardArrowDown>
                                        </Button>
                                    </div>
                                    <br/><img src={logoApollo} class="logoApolloMedco"/>
                                    
                                </p>
                                <p style={{fontWeight:"100",fontSize:"15px"}}>Global Medical Solutions</p>
                        </div>
                    </div>
                </div>
                <div style={{textAlign:"center"}}>
                    <FormLandPage/>
                </div>
            </div>

            {/*
            <Modal id="modalFormTesting" style={{textAlign:"center!important"}} className="modalFormTesting" show={show} onHide={handleClose}>
                
                <Modal.Header style={{borderBottom:"none"}} closeButton>
                    <Modal.Title style={{background:"#da783b",color:"white",padding:"5px",borderRadius:"5px"}}>Request for COVID-19 Testing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form noValidate autoComplete="off">
                        <RadioGroup style={{textAlign:"center",marginLeft:"auto",marginRight:"auto"}} row aria-label="choiseTesting" name="choiceTesting" value={value} onChange={handleChange}>
                            <FormControlLabel style={{diplay:"inline"}} value="needTesting" control={<GreyRadio />} label="I need testing" />
                            <FormControlLabel style={{diplay:"inline"}} value="wantToBeTester" control={<GreyRadio />} label="I want to be a tester" />
                        </RadioGroup>
                        <p><CssTextField fullWidth id="outlined-basic" className="textInputForm" label="Company Name" variant="outlined" /></p>
                        <p><CssTextField fullWidth id="outlined-basic" className="textInputForm" label="Contact Name" variant="outlined" /></p>
                        
                        <p><CssTextField fullWidth id="outlined-basic" className="textInputForm" label="Telephone" variant="outlined" /></p>
                        <p><CssTextField fullWidth id="outlined-basic" className="textInputForm" label="Email" variant="outlined" /></p>
                        
                        <p><CssTextField fullWidth id="outlined-basic" className="textInputForm" label="CLIA license number(only if they choose to be a test partner)" variant="outlined" /></p>
                        <p><CssTextField rows={4} fullWidth multiline id="outlined-basic" className="textInputForm" label="Tell us about your company and Covid 19 testing needs." variant="outlined" /></p>
                        
                    </form>
                        
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained" style={{background:"#46383f",color:"white"}} onClick={handleClose}>
                    Apply
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal> */}
        </StyledBody>
    );
}

export default LandPage;