import React from 'react';
import {BrowserRouter as Router,Route, Switch} from 'react-router-dom';
import Home from './pages/Home';
import Us from './pages/Us';
import Products from './pages/Products';
import TrainingSupport from './pages/TrainingSupport';
import Vortex from './pages/Vortex';
import NoMatch from './pages/NoMatch';
import Layout from './Component/Layout';
import NavigationBar from './Component/NavigationBar';
import LandPage from './pages/LandPage';
import FooterBar from './Component/FooterBar';


function App() {
  return (
    
    <React.Fragment>
      <Layout>
        <NavigationBar/>
        <Router>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/home" component={Home}/>
            <Route exact path="/us" component={Us}/>
            <Route exact path="/about" component={Us}/>
            <Route exact path="/products" component={Products}/>
            <Route exact path="/trainingandsupport" component={TrainingSupport}/>
            <Route exact path="/vortex" component={Vortex}/>
            <Route exact path="/landpage" component={LandPage}/>
            <Route component={NoMatch}/>
            {/* <Route exact path="/" component={LandPage}/>
            <Route component={LandPage}/>  */}
          </Switch>
        </Router>
        <FooterBar/>
      </Layout>
    </React.Fragment>
  );
}

export default App;
