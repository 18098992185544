import React from "react";
import Col from "react-bootstrap/Col";
import "../../style/AdvantageUseType3.css";
import imgMachine from '../../res/img/machine.png';
import logoAlphaBg from '../../res/img/logo.alpha.png';
import imgLargeAdvantage3 from '../../res/img/img.large.advtg3.png';
import imgMachine2 from '../../res/img/machine2.png';
import imgClearPassBlue from '../../res/img/ClearPassBlue.png';

import Img1Adv3 from '../../res/img/advantages/adv2/img1.png';
import Img2Adv3 from '../../res/img/advantages/adv2/img2.png';
import Img3Adv3 from '../../res/img/advantages/adv2/img3.png';
import Img4Adv3 from '../../res/img/advantages/adv2/img4.png';

import { SRLWrapper } from "simple-react-lightbox";

const captionOne = <div myCustomCaptionOne="">
                        <h1>1 single minute</h1>
                        <p>... to test any body,. Another 14, to have the result.</p>
                    </div>

const captionTwo = <div myCustomCaptionOne="">
                        <h1>Welcome a blackout!</h1>
                        <p>... to test any body,. Another 14, to have the result.</p>
                    </div>

const captionThree = <div myCustomCaptionOne="">
                        <h1>Electricity? That's so 2020</h1>
                        <p>... to test any body,. Another 14, to have the result.</p>
                    </div>

const captionFour = <div myCustomCaptionOne="">
                        <h1>No joke. Only 1/2 page instructional manual</h1>
                        <p>... to test any body,. Another 14, to have the result.</p>
                    </div>
 


const customCaptions = [
    { id: 0, caption: captionOne },
    { id: 1, caption: captionTwo },
    { id: 2, caption: captionTwo },
    { id: 3, caption: captionTwo }
  ]

class AdvantageUseType3 extends React.Component{
    getImageList = (number) => {
        if(number === 1){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img src={Img1Adv3}/>
                    <img style={{display:"none"}} src={Img2Adv3}/>
                    <img style={{display:"none"}} src={Img3Adv3}/>
                    <img style={{display:"none"}} src={Img4Adv3}/>
                </SRLWrapper>
            );
        }else if(number === 2){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img style={{display:"none"}}  src={Img1Adv3}/>
                    <img src={Img2Adv3}/>
                    <img style={{display:"none"}} src={Img3Adv3}/>
                    <img style={{display:"none"}} src={Img4Adv3}/>
                </SRLWrapper>
            );
        }else if(number === 3){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img style={{display:"none"}}  src={Img1Adv3}/>
                    <img style={{display:"none"}} src={Img2Adv3}/>
                    <img src={Img3Adv3}/>
                    <img style={{display:"none"}} src={Img4Adv3}/>
                </SRLWrapper>
            );
        }else if(number === 4){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img style={{display:"none"}}  src={Img1Adv3}/>
                    <img style={{display:"none"}} src={Img2Adv3}/>
                    <img style={{display:"none"}} src={Img3Adv3}/>
                    <img src={Img4Adv3}/>
                </SRLWrapper>
            );
        }
    };

    render(){
        return (
            <Col  md={6} lg={3} className="advantageUsing3">
                <div className="borderDiv"></div>
                <div className="borderDiv1"></div>
               <div className="zone1SingleMinute"><div>{this.props.title}</div></div>
                <div className="zoneBig2">
                    <div>
                        <div className="zoneTestAnyBodyBigText">... to test any body,. Another 14, to have the result.</div>
                        <div className="zoneImgBigTestAnyBody">
                            {this.getImageList(this.props.number)}
                        </div>
                        <div className="zoneTestAnyBodySmallText">
                            to test any body,. Another 14, to have the result to test any body,. Another 14, to have the result
                            
                        </div>
                        <div className="zoneTestImgSmallText">
                            <img  className="imgMachineTestAnyBody" src={imgMachine2}/>
                            <img  className="imgMachineLogoAnyBody" src={imgClearPassBlue}/>
                        </div>
                    </div>
                </div>

            </Col>
        );
    }
}

export default AdvantageUseType3;