import React from "react";
import Col from "react-bootstrap/Col";
import "../../style/AdvantageUseType2.css";
import imgMachine from '../../res/img/machine.png';
import logoAlphaBg from '../../res/img/logo.alpha.png';
import proWoman from '../../res/img/img.woman.pro.png';
import { SRLWrapper } from "simple-react-lightbox";

import Img1Adv2 from '../../res/img/advantages/adv1/img1.png';
import Img2Adv2 from '../../res/img/advantages/adv1/img2.png';
import Img3Adv2 from '../../res/img/advantages/adv1/img3.png';
import Img4Adv2 from '../../res/img/advantages/adv1/img4.png';

const captionOne = <div myCustomCaptionOne="">
                        <h1>Don't just kiss under the mistletoe. Test as well</h1>
                        <p>Covid testing is as easy as fixing a drinking at any familfy gathering, holiday celebreation, or high school graduation.</p>
                    </div>

const captionTwo = <div myCustomCaptionOne="">
                        <h1>A business doors, opening app</h1>
                        <p>Covid testing is as easy as fixing a drinking at any familfy gathering, holiday celebreation, or high school graduation.</p>
                    </div>

const captionThree = <div myCustomCaptionOne="">
                        <h1>Bye bye & comply</h1>
                        <p>Covid testing is as easy as fixing a drinking at any familfy gathering, holiday celebreation, or high school graduation.</p>
                    </div>

const captionFour = <div myCustomCaptionOne="">
                        <h1>Keep them! Since all are true Mvp's</h1>
                        <p>Covid testing is as easy as fixing a drinking at any familfy gathering, holiday celebreation, or high school graduation.</p>
                    </div>
 


const customCaptions = [
    { id: 0, caption: captionOne },
    { id: 1, caption: captionTwo },
    { id: 2, caption: captionTwo },
    { id: 3, caption: captionTwo }
  ]

class AdvantageUseType2 extends React.Component{
    getImageList = (number) => {
        if(number === 1){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img src={Img1Adv2}/>
                    <img style={{display:"none"}} src={Img2Adv2}/>
                    <img style={{display:"none"}} src={Img3Adv2}/>
                    <img style={{display:"none"}} src={Img4Adv2}/>
                </SRLWrapper>
            );
        }else if(number === 2){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img style={{display:"none"}}  src={Img1Adv2}/>
                    <img src={Img2Adv2}/>
                    <img style={{display:"none"}} src={Img3Adv2}/>
                    <img style={{display:"none"}} src={Img4Adv2}/>
                </SRLWrapper>
            );
        }else if(number === 3){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img style={{display:"none"}}  src={Img1Adv2}/>
                    <img style={{display:"none"}} src={Img2Adv2}/>
                    <img src={Img3Adv2}/>
                    <img style={{display:"none"}} src={Img4Adv2}/>
                </SRLWrapper>
            );
        }else if(number === 4){
            return (
                <SRLWrapper customCaptions={customCaptions}>
                    <img style={{display:"none"}}  src={Img1Adv2}/>
                    <img style={{display:"none"}} src={Img2Adv2}/>
                    <img style={{display:"none"}} src={Img3Adv2}/>
                    <img src={Img4Adv2}/>
                </SRLWrapper>
            );
        }
    };

    render(){
        return (
            <Col md={6} lg={3} className="advantageUsing2">
                <div class="zoneLeft"><div>{this.props.title}</div></div>
                <div class="biggestImageZone">
                    
                       {this.getImageList(this.props.number)}
                    
                    
                </div>
                <div class="zoneRight">
                    <div>
                        <div className="rightText1">Covid testing is as easy as fixing a drinking at any familfy gathering, holiday celebreation, or high school graduation.</div>
                        <div className="rightText2">Covid testing is as easy as fixing a drinking at any familfy gathering, holiday celebreation, or high school graduation ipsum</div>
                        <div>
                            <img className="imgBigAdvtg2" src={imgMachine}/><br/>
                            <img className="imgSmallAdvtg2"  src={logoAlphaBg}/>

                        </div>
                    </div>
                </div>


            </Col>
        );
    }
}

export default AdvantageUseType2;