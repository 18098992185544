import React from 'react';
import styles from 'styled-components';
import logoApollo from '../res/img/logo.alpha.png';
import bgImgBigA from '../res/img/a.icon.transparent.png';
import smileMan from '../res/img/smile.man.png';
import Parallax from 'react-parallax';
import Button from '@material-ui/core/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import grey from '@material-ui/core/colors/grey';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller, animateScroll } from 'react-scroll'
import axios from 'axios';
import querystring from 'querystring';

import {
    createStyles,
    fade,
    Theme,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
  } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';


const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'black',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'gray',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#f7f7f7',
        },
        '&:hover fieldset': {
          borderColor: '#f7f7f7',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#f7f7f7',
        },
      },
    },
  })(TextField);

const GreyRadio = withStyles({
    root: {
      color: grey[400],
      '&$checked': {
        color: grey[600],
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

  const goUpPage = () => {
    scroll.scrollTo(0);
  };

  const StylesForm = styles.div`
        .zoneOutForm{
            width:500px;
            display:inline-block;
            paddingBottom:5cm;
        }

        #zoneFormID{
            background: linear-gradient(0deg,#beaab7 20%,#d8c4d1 50%);
            padding:10px;
            border-radius:5px;
            box-shadow:0px 0px 5px white;
            margin-bottom:2cm;
            margin-top:20px;
        }

        @media only screen and (max-width: 650px) {
            .zoneOutForm{
                width:300px;
                display:inline-block;
                paddingBottom:5cm;
            }
        }
  `;

const setAxiosToken = (token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';//'application/json; charset=utf-8'
}

const handleChange = (event) => {
  //setValue(event.target.value);
};

const handleChangeInput = (event) => {
  console.log(event);
  //companyName = event.target.value;
};

class FormLandPage extends React.Component{
  //const [value, setValue] = React.useState('wantToBeTester');

  //console.log("Checking different values",[value, setValue]);
  state = {
    companyName : "",
    value : 'wantToBeTester',
    contactName : '',
    telephone : '',
    email : '',
    clia : '',
    tellmore : '',
    commpanyError:false,
    contactNameError:false,
    telephoneError:false,
    emailError:false,
    cliaError:false,
    tellmoreError:false,
    afterSending:'',
    afterSendingColor:''

  }

  handleChange = (event) => {
    this.setState({
      value : event.target.value
    });

    this.setState({ cliaError:false});
    this.setState({ emailError:false});
    this.setState({ companyError:false});
    this.setState({ telephoneError:false});
    this.setState({ cliaError:false});
  };

  onChangeTextField = (e) => this.setState({[e.target.name]:e.target.value});

  sendRequestCOVTest = () => {
      if(this.state.afterSending == "Thank you for registering." || this.state.afterSending == "You are already registered."){
        this.setState({afterSending:"You are already registered."});
        return;
      }
      if(!this.state.companyName){
        this.setState({ companyError:true});
      }else if(!this.state.contactName){
        this.setState({ companyError:false});
        this.setState({ contactNameError:true});
      }else if(!this.state.telephone){
        this.setState({ contactNameError:false});
        this.setState({ companyError:false});

        this.setState({ telephoneError:true});
      }else if(!this.state.email){
        this.setState({ telephoneError:false});
        this.setState({ contactNameError:false});
        this.setState({ companyError:false});

        this.setState({ emailError:true});
      }else if(!(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email))){
        this.setState({ telephoneError:false});
        this.setState({ contactNameError:false});
        this.setState({ companyError:false});

        this.setState({ emailError:true});

      }else if(!this.state.clia){
        this.setState({ emailError:false});
        this.setState({ companyError:false});
        this.setState({ contactNameError:false});
        this.setState({ telephoneError:false});

        this.setState({ cliaError:true});
      }else{
        this.setState({ cliaError:false});
        this.setState({ emailError:false});
        this.setState({ companyError:false});
        this.setState({ telephoneError:false});
        this.setState({ cliaError:false});

        const requestBody = {
          testingType:this.state.value,
          company:this.state.companyName,
          name:this.state.contactName,
          telephone:this.state.telephone,
          email:this.state.email,
          clia:this.state.clia,
          tellmore:this.state.tellmore
        }
      
        axios.post(
              "https://proxyapollo.nfinic.com",
              querystring.stringify(requestBody),
              {headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
        ).then((e) => {
            console.log(e.data);
            //alert("Sent")
            this.setState({
              afterSending:"Thank you for registering.",
              afterSendingColor:"rgb(70, 56, 63)"
            });
        }).catch((e) => {
          //console.log("Something went wrong ",e)
          this.setState({
            afterSending:"An error occured when sending data, please try to send again.",
            afterSendingColor:"red"
          });
        });

      }
      
      
    };

  render(){
    return (
      <StylesForm>
          <div className="zoneOutForm">
                          <form id="zoneFormID" noValidate autoComplete="off" >
                              <div style={{textTransform:"uppercase",background:"#da783b",display:"inline-block",color:"white",padding:"5px",borderRadius:"5px"}}>Request for COVID-19 Testing</div>
                                  <RadioGroup style={{textAlign:"center",marginLeft:"auto",marginRight:"auto"}} row aria-label="choiseTesting" name="choiceTesting" value={this.state.value} onChange={this.handleChange}>
                                      <FormControlLabel style={{diplay:"inline"}} value="needTesting" control={<GreyRadio />} label="I need testing" />
                                      <FormControlLabel style={{diplay:"inline"}} value="wantToBeTester" control={<GreyRadio />} label="I want to be a tester" />
                                  </RadioGroup>
                                  <p><CssTextField error={this.state.companyError} name="companyName" value={this.state.companyName}  onChange={this.onChangeTextField} fullWidth id="outlined-basic" className="textInputForm" label="Company Name" variant="outlined" /></p>
                                  <p><CssTextField error={this.state.contactNameError} fullWidth id="outlined-basic" name="contactName"  value={this.state.contactName}  onChange={this.onChangeTextField} className="textInputForm" label="Contact Name" variant="outlined" /></p>
                                  
                                  <p><CssTextField error={this.state.telephoneError} fullWidth id="outlined-basic" name="telephone"  value={this.state.telephone}  onChange={this.onChangeTextField} className="textInputForm" label="Telephone" variant="outlined" /></p>
                                  <p><CssTextField error={this.state.emailError} fullWidth id="outlined-basic" name="email"  value={this.state.email}  onChange={this.onChangeTextField} className="textInputForm" label="Email" variant="outlined" /></p>
                                  
                                  <p><CssTextField error={this.state.cliaError} fullWidth id="outlined-basic" name="clia"  value={this.state.clia}  onChange={this.onChangeTextField} className="textInputForm" label="CLIA license number(only if they choose to be a test partner)" variant="outlined" /></p>
                                  <p id="TellMoreId"><CssTextField error={this.state.tellmoreError} rows={4} fullWidth multiline id="outlined-basic" name="tellmore"  value={this.state.tellmore}  onChange={this.onChangeTextField} className="textInputForm" label="Tell us about your company and Covid 19 testing needs." variant="outlined" /></p>
                                  <Button onClick={this.sendRequestCOVTest} variant="contained" style={{background:"#46383f",color:"white"}}>Apply</Button>
                                  <Button variant="secondary"  onClick={goUpPage}><KeyboardArrowUp></KeyboardArrowUp> Go up</Button>
                                  <p style={{color:this.state.afterSendingColor,marginTop:'10px'}}>{this.state.afterSending}</p>
                          </form>
                      </div>
          </StylesForm>
    );
  }
  
}

/*
function FormLandPage1(){
    const [value, setValue] = React.useState('wantToBeTester');

    console.log("Checking different values",[value, setValue]);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    let companyName = "fdsaf";

    const handleChangeInput = (event) => {
      console.log(event);
      //companyName = event.target.value;
    };

    return (
        <StylesForm>
            <div className="zoneOutForm">
                            <form id="zoneFormID" noValidate autoComplete="off" >
                                <div style={{textTransform:"uppercase",background:"#da783b",display:"inline-block",color:"white",padding:"5px",borderRadius:"5px"}}>Request for COVID-19 Testing</div>
                                    <RadioGroup style={{textAlign:"center",marginLeft:"auto",marginRight:"auto"}} row aria-label="choiseTesting" name="choiceTesting" value={value} onChange={handleChange}>
                                        <FormControlLabel style={{diplay:"inline"}} value="needTesting" control={<GreyRadio />} label="I need testing" />
                                        <FormControlLabel style={{diplay:"inline"}} value="wantToBeTester" control={<GreyRadio />} label="I want to be a tester" />
                                    </RadioGroup>
                                    <p><CssTextField value={companyName}  onChange={handleChangeInput(this)} fullWidth id="outlined-basic" className="textInputForm" label="Company Name" variant="outlined" /></p>
                                    <p><CssTextField fullWidth id="outlined-basic" className="textInputForm" label="Contact Name" variant="outlined" /></p>
                                    
                                    <p><CssTextField fullWidth id="outlined-basic" className="textInputForm" label="Telephone" variant="outlined" /></p>
                                    <p><CssTextField fullWidth id="outlined-basic" className="textInputForm" label="Email" variant="outlined" /></p>
                                    
                                    <p><CssTextField fullWidth id="outlined-basic" className="textInputForm" label="CLIA license number(only if they choose to be a test partner)" variant="outlined" /></p>
                                    <p><CssTextField rows={4} fullWidth multiline id="outlined-basic" className="textInputForm" label="Tell us about your company and Covid 19 testing needs." variant="outlined" /></p>
                                    <Button onClick={sendRequestCOVTest} variant="contained" style={{background:"#46383f",color:"white"}}>Apply</Button>
                                    <Button variant="secondary"  onClick={goUpPage}><KeyboardArrowUp></KeyboardArrowUp> Go up</Button>
                                
                            </form>
                        </div>
            </StylesForm>
    );
}*/

export default FormLandPage;