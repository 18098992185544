import React from "react";
import Col from "react-bootstrap/Col";
import "../../style/AdvantageUse.css";
import imgMachine from '../../res/img/machine.png';
import ManBread from  '../../res/img/man.bread.pic.png';

import Machine3 from '../../res/img/machine.new.png';

import Case from '../../res/img/case.png';
import SingleMachine from '../../res/img/machineTransparentBG.png';

//import { useLightbox } from "simple-react-lightbox";
import ButtonLightBoxOpen from './ButtonLightBoxOpen';
import { SRLWrapper } from "simple-react-lightbox";

const captionOne = <div myCustomCaptionOne="">
                        <h1>BE BACK</h1>
                        <p>You can be back to work, at possibly full capacity, in a hassle-free way & with undeniable peace of mind.</p>
                    </div>
 
const captionTwo = <div myCustomCaptionOne="">
                        <p>Uncover today the unprecedented, 15 minute, APP linked, HIPAA Compliant, Cloud Secure, & Data Backed COVID Testing Opportunity.</p>
                    </div>
 


const customCaptions = [
    { id: 0, caption: captionOne },
    { id: 1, caption: captionTwo }
  ]

  const options = {
    settings: {
        display:"none"
    },
    caption: {},
    buttons: {
        display:"none"
    },
    thumbnails: {},
    progressBar:{}
  }


class AdvantageUse extends React.Component{
    

    render(){
        

        return (
            <React.Fragment>
                
                    <Col md={6} lg={3} className1={this.props.classes}>
                        <SRLWrapper customCaptions={customCaptions} options={options}>
                            <img style={{width:"100%"}} src={Machine3}/>
                        </SRLWrapper>
                    </Col>
                
                
            </React.Fragment>
        );
    }
}

export default AdvantageUse;