import React from 'react';
import Header from '../Component/Header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import JumbotronA from '../Component/JumbotronA';
import { Redirect } from 'react-router-dom';

import OldWomanSmiles from '../res/img/home/old.woman.png';
import SisterAndBrotherKids from '../res/img/home/siter.and.brother.kids.png';
import ApolloRedIcon from '../res/img/home/apollo.red.icon.png';
import ClearPassBlue from '../res/img/home/clean.pass.blue.png';
import SmilingGuy from '../res/img/home/guy.apollo.png';

import Case from '../res/img/home/case.png';
import Machine from '../res/img/home/machine.png';

import {ParallaxProvider,Parallax,ParallaxBanner} from 'react-scroll-parallax';

import IconApolloBlack from '../res/img/training/icon.apollo.black.png';

import Vortex from '../res/img/training/vortex.png';

import "../style/Home.css";

class Home extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div id="zonePageHomeReact_">
                <ParallaxProvider>
                    <div id="premierDivUs" className="bigBackgroundsInApp" style={styleTopDiv}>

                    <div id="ChooseBusinessOrClinicHome">
                            <div>
                                <p>
                                    <Button className="buttonApollo">Schedule Covid Testing</Button> 
                                    <Button className="buttonApollo">Become a Tester Partner</Button>
                                </p>
                                {/*<img id="IconApolloBlack" src={IconApolloBlack}/>*/}
                            </div>
                        </div>

                    </div>

                    <div className="divAfterBigDivStarting" id="zoneDiscoverHowUniqueHome">
                        <div>
                            Discover how a unique company, driven by the aspiration to offer farseeing solutions, is catching the attention of 
                            <b> decision makers across the global, medical industry</b>; not only because of the features distinguishing their product 
                            line, but because of their determination to build a company based on <b>one single, old school, characteristic</b>; 
                            <i>a characteristic treasured by many business leaders yet rarely experienced by any of them.</i>
                        </div>
                    </div>
                    <Row id="row-text-single-characteristic">
                        <Col md={7}>
                            <div id="text-single-characteristic">
                                <div>
                                    <div>
                                    This single characteristic, blended with a dedication to enhance people's lives and be first-to-market with products few knew were possible, is the significant reason why Apollo MedCo is enabling companies to reach their financial & socially responsible growth goals.
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={5} >
                            <div id="img-single-characteristic">
                                <Parallax className="custom-class" x={[-30, 0]} tagOuter="figure">
                                <img src={SisterAndBrotherKids}/>
                                </Parallax>
                            </div>
                            
                        </Col>
                    </Row>

                    <div id="zoneApolloRedIcon">
                        <div style={{position:"relative",zIndex:"1500"}}>
                            <img id="imgApolloRedIcon" src={ApolloRedIcon}/>
                            <div id="Because-Reliabilit-is-Truly">
                                Because Reliabilit is Truly Innovative&trade;</div>
                        </div>
                        
                        

                        <div id="our-latest-solution-home">
                            <div><strong><b>Our latest solution:</b></strong></div>
                            <div><img style={{marginTop:"1cm",height:"2.5cm"}} src={ClearPassBlue}/></div>
                            <div id="txt-our-latest-solution-home">
                                During these incredibly difficult times, the <b>COVID Clean Pass</b> represents the finest opportunity for peace of mind, 
                                for reopening businesses, and for positioning your business to be on its next growth path.
                            </div>
                        </div>

                        {/*<div id="home-display-apollo-case" style={{display:"none"}}>
                            <Parallax x={[-100,0]} y={[0,0]}>
                                <img src={Case}/>
                                <img src={Machine}/>
                            </Parallax> 
                            --<Parallax  x={[100,0]}  style={{display:"inline"}}>
                                <img src={Machine}/>
                                </Parallax>
                        </div>

                        <div id="home-display-apollo-machine">
                            <img id="home-apollo-case" src={Case}/>
                            <img id="home-apollo-machine1" src={Machine}/>
                        </div>*/}

                        <div id="Uncover-today-the-unprecedented">
                            <img id="home-apollo-case" src={Case}/>
                            <p>
                                Uncover today the unprecedented, <b><i>15 minute, APP linked, HIPAA Compliant, Cloud Secure, Data Backed</i></b> COVID Testing Opportunity.
                                <p><Button className="buttonApollo">Learn more</Button> </p>    
                            </p>
                            <img id="home-apollo-machine1" src={Machine}/>
                            
                        </div>

                        

                        
                    </div>

                    <div  className="bigBackgroundsInApp" style={styleSmilingGuyZone} id="zoneSmilingGuy">
                            <img src={ClearPassBlue}/>
                    </div>

                    <div id="zoneDisplayVortexImage" style={styleShowVortexTraining}>
                        <div>
                            <img src={Vortex}/>
                        </div>
                    </div>
                </ParallaxProvider>
                </div>
            </React.Fragment>

        )
    }
}


const styleTopDiv = {
    width:'100%',
    background:'url('+OldWomanSmiles+') no-repeat fixed top',
    backgroundSize:'cover',
    color: '#ccc',
    height: '700px',
    position: 'absolute',
    top:'0px',
    left:'0px',
    zIndex:'-2',
    color:'black'
    
};

const styleSmilingGuyZone = {
    width:'100%',
    background:'url('+SmilingGuy+') no-repeat fixed top',
    backgroundSize:'cover',
    color: '#ccc',
    height: '700px',
    position:'relative',
    color:'black'

};

const styleShowVortexTraining = {
    width:'100%',
    background:'url('+IconApolloBlack+') no-repeat center ',
    backgroundSize:'cover',
    color: '#ccc',
    height: '400px',
    zIndex:'-2',
    color:'black',
    overflow:'hidden'
};

export default Home;