import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styles from 'styled-components';
import { NavbarBrand } from 'react-bootstrap';
import logoAlphaBg from '../res/img/logo.alpha.png';

const StylesN = styles.div`
                        .navbar{
                            color:#ffffff!important;
                            /*background:#977D8E;*/
                            width:100%;
                            z-index:1500;
                        }

                        .navbar-brand,.navbar-nav,.nav-link,.nav-item.nav-link{
                            /*color:#ffffff!important;*/
                            text-transform:uppercase;
                        }

                        .nav-item.nav-link :hover{
                            text-decoration:underline!important;
                        }

                        .navLinkBstrp{
                            color:#ffffff;
                        }

                        .linkNavigationBar{
                            color:white!important;
                        }

                        #navigationBarBrand{
                            color:white;
                        }

                        #logoBarBrand{
                            height:60px;
                        }

                        @media only screen and (max-width: 1010px) {
                            .navbar-collapse{
                                background-color:rgba(133,98,121,0.8);
                                text-align:center;
                            }
                            
                            .navbar-toggler{
                                border: 1px solid #856379;
                                background: rgba(204,165,190,0.5);
                                border-style:dotted;
                            }

                            .navbar-toggler:focused{
                                border:none!important;
                            }

                            .navbar-toggler-icon{
                                
                            }
                        }
`;

function NavigationBar(){
    return (
        <StylesN>
            <Navbar expand="lg">
                <Navbar.Brand id="navigationBarBrand" href="/"><img id="logoBarBrand" src={logoAlphaBg}/> </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Item><Nav.Link className="linkNavigationBar" href="/" >Home</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link className="linkNavigationBar" href="/us">Us</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link className="linkNavigationBar" href="/vortex">Vortex of Reliability</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link className="linkNavigationBar" href="/products">Products</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link className="linkNavigationBar" href="/trainingandsupport">Training & Support</Nav.Link></Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </StylesN>
    );
}

export default NavigationBar;