import React from 'react';
import ReactDOM from 'react-dom';
import logoAlpha1 from '../res/img/logo.alpha.png'

class Header extends React.Component{
    render(){
        return(
            <div>
                Main page
                <img src={logoAlpha1}/>
                <div>
                    <ul>
                        <li>Home</li>
                        <li>Us</li>
                        <li>Vortex of reliability</li>
                        <li>Products</li>
                        <li>Training & Support</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Header;